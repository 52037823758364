import { useRouter } from "next/router";
import { signIn } from "next-auth/client";
import { useTranslation } from "react-i18next";

import Button from "../ui/Button";

interface Props {
  provider: { id: string; name: string };
  context: "login" | "signup";
}

const OAuthProvider = ({ provider, context }: Props) => {
  const { t } = useTranslation();
  const router = useRouter();

  return (
    <div key={provider.name}>
      <Button
        type="outline"
        block
        onClick={() =>
          signIn(provider.id, {
            callbackUrl: router.query.callbackUrl as string | undefined,
          })
        }
        icon={<img src={`/auth-icons/${provider.id}.svg`} className="mr-2" alt="" />}
      >
        {t(`auth.sign-in-provider.${context}`, "Sign in with {{name}}", {
          name: provider.name,
        })}
      </Button>
    </div>
  );
};

export default OAuthProvider;
